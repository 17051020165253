import React, { useState, useEffect } from "react";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

export const PdfViewer = ({ pdfUrl, selectedPage, setError, setPdfViewerDisplay, setSelectedPdfUrl }) => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfObjectUrl, setPdfObjectUrl] = useState(null);

  useEffect(() => {
    if (pdfUrl) {
      // Fetch the PDF and store it as a blob
      const fetchPdf = async () => {
        try {
          const response = await fetch(pdfUrl, {mode:"cors"});
          if (!response.ok) {
            setPdfViewerDisplay('none');
            throw new Error('Network response was not ok. ' + response.statusText);
          }
          const blob = await response.blob();
          setPdfBlob(blob);
        } catch (error) {
          setError('Error Loading the PDF file.');
          setSelectedPdfUrl(null);
          setPdfViewerDisplay('none');
          console.error('Fetching PDF failed. ', error);
        }
      };

      fetchPdf();
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      setPdfObjectUrl(url);
      setPdfViewerDisplay('block');
      // Clean up object URL to prevent memory leak
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [pdfBlob]);

  // Plugins
  const layoutPluginInstance = defaultLayoutPlugin({
    defaultPageLayoutMode: 'Single',
  });
  const pageNavigationPluginInstance = pageNavigationPlugin();

  return (
    pdfObjectUrl && (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer 
          fileUrl={pdfObjectUrl}
          plugins={[layoutPluginInstance, pageNavigationPluginInstance]}
          initialPage={selectedPage['0']-1}
        />
      </Worker>
    )
  );
};

export default PdfViewer;
