// Basic hashing function for a string
function hashString(str) {
    let hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
}

// Stores a string in local storage and returns the hash
export function storeStringAndGetHash(str) {
    const hash = hashString(str);
    localStorage.setItem(hash, str);
    return hash;
}

// Retrieves a string from local storage based on a hash from the URL
export function getStringFromUrlKey(key) {
    const queryParams = new URLSearchParams(window.location.search);
    const hash = queryParams.get(key);

    if (hash) {
        return localStorage.getItem(hash);
    }
    return null;
}

export function storeObjectAndGetHash(obj) {
    const serializedObject = JSON.stringify(obj);
    const hash = hashString(serializedObject);
    localStorage.setItem(hash, serializedObject);
    return hash;
}

export function getObjectFromUrlKey(key) {
    const queryParams = new URLSearchParams(window.location.search)
    const hash = queryParams.get(key)
    if (hash) {
        const serializedObject = localStorage.getItem(hash);
        if (serializedObject) {
            return JSON.parse(serializedObject);
        }
    }
    return null;
}