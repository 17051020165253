import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import './VectorSearchTable.css';

const SearchTable = (inputData) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    //Every time new data come, this is triggered
    inputData = inputData?.inputData?.data;
    if (!inputData || !inputData.length || inputData?.length === 0) {
      setData([]);
      return;
    } else {
      //create a flat map from the list of documents and iterate
      const data = inputData?.flatMap((document) => {
        //results references a list of objects inside search_result of each document
        const results = document.search_result.map((search_result) => ({
          referenceText: search_result.full_text,
          document: `${document.documentName} (Page ${search_result.page}, Lines ${search_result.lines})`,
          score: search_result.score,
          uploadDate: document.metadata.UploadTime,
          author: `Author -> ${document.metadata.Author}\n tags -> ${document.metadata.Tags}`,
          text: search_result.text
        }));
        return results;
      });
      setData(data);
      console.log(data);
    }
  }, [inputData]);

  const applyHighlighting = (fullText, textToHighlight) => {
    if (typeof fullText !== 'string') {
      return fullText;
    }
  
    const indexToHighlight = fullText.indexOf(textToHighlight);
  
    if (indexToHighlight === -1) {
      // If the textToHighlight is not found, return the original text
      return <span style={{ color: 'darkgrey' }}>{fullText}</span>;
    }
  
    // Slice the full text to extract the highlighted sentence
    const beforeHighlight = fullText.slice(0, indexToHighlight);
    const highlightedText = fullText.slice(indexToHighlight, indexToHighlight + textToHighlight.length);
    const afterHighlight = fullText.slice(indexToHighlight + textToHighlight.length);
  
    return (
      <span>
        <span className="grey_text">{beforeHighlight}</span>
        <span className="highlighted">{highlightedText}</span>
        <span className="grey_text">{afterHighlight}</span>
      </span>
    );
  };
  
  
  

  const columns = useMemo(
    () => [
      {
        accessorKey: 'referenceText', //access nested data with dot notation
        header: 'Text',
        size: 600,
        accessorFn: (dataRow) => applyHighlighting(dataRow.referenceText, dataRow.text),
      },
      {
        accessorKey: 'document',
        header: 'Document',
        size: 150,
      },
      {
        accessorKey: 'score', //normal accessorKey
        header: 'Similarity Score',
        size: 200,
      },
      {
        accessorKey: 'uploadDate',
        header: 'Upload date',
        size: 150,
      },
      {
        accessorKey: 'author',
        header: 'Meta data',
        size: 150,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: { maxHeight: '600px' },
    },
    muiTableBodyCellProps:{
      sx: {verticalAlign: "text-top"}
    }
  });

  return <div>
    <MaterialReactTable table={table} />
  </div>;
};

export default SearchTable;
