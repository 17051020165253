import React, { useState, useEffect } from 'react';
import './errorBanner.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ErrorBanner({ message, errorSetter }) {
  const [error, setError] = useState(message);

  const closeError = () => {
    setError(null);
    errorSetter(null);
  };

  useEffect(() => {
    // When a new error message is set, show the banner
    if (message) {
      setError(message);
      document.body.style.overflow = 'hidden';
      // Make it pop up from below
      setTimeout(() => {
        setError(message);
      }, 10); // A small delay to ensure the state update is processed first
    } else {
      // If the error message is cleared, hide the banner with a delay
      setTimeout(() => {
        setError(null);
        document.body.style.overflow = 'auto';
      }, 500); // Adjust the delay time as needed
    }
  }, [message]);

  return (
    error && (
      <div className='error-banner-container'>
        <div className="error-banner">
          <IconButton onClick={closeError} style={{ zIndex: 1, color: 'white' }}>
            <CloseIcon />
          </IconButton>
          <div className="error-message">{error}</div>
        </div>
      </div>
    )
  );
}

export default ErrorBanner;
