import React, { useState } from "react";
import {Box, Stack, Paper, InputBase } from '@mui/material';
import SearchTable from '../features/VectorSearchTable/VectorSearchTable.jsx';
import './VectorSearchPage.css'; // Import the CSS file
import api from "../config/axiosConfigs";

function VectorSearchPage() {
    const [timeoutId, setTimeoutId] = useState(null);
    const [searchResponse, setSearchResponse] = useState(null);
    const [isWorking, setIsWorking] = useState(null);

    const handleInputChange = (event) => {
        const SearchQuery = event.target.value;
        setIsWorking(true);
        // Clear the previous timeout to avoid making unnecessary API calls
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
    
        // Set a new timeout for 3 seconds before making the API call
        const newTimeoutId = setTimeout(() => {
            
            const data = {
                "query": SearchQuery
                }
            api.post('/vectorSearch', {
                body: data,
                mode: 'cors'
            }).then( (response) => {
                setSearchResponse(response.data);
                resetWorkingAnimation();
            }
            ).catch(()=> {
                setIsWorking(false);
            })
        }, 300);
        // Save the new timeoutId in the component state
        setTimeoutId(newTimeoutId);
      };

    const resetWorkingAnimation = () => {
        setTimeout(() => {
           setIsWorking(false); 
        }, 3000);
    };

    return (
        <div className="vector-search-page">
            <div className="content-container">
                <Box className="vector-search-container">
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        className="search-input-container">
                        <Paper
                            component="form"
                            elevation={4}
                            className="search-input-paper">
                            <InputBase 
                                className='search-input'
                                multiline
                                placeholder="Please enter search query"
                                inputProps={{ "aria-label": "search google maps" }}
                                onChange={handleInputChange}
                            />
                            {isWorking && 
                            <div class="loading-container">
                                <div class="loading-ball"></div>
                            </div>
                            }
                        </Paper>
                        <SearchTable inputData={searchResponse}/>
                    </Stack>
                </Box>
            </div>
        </div>
    );
}




export default VectorSearchPage;
