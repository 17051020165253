const prod = {
    API_URL: process.env.API_ENDPOINT !== undefined ? process.env.API_ENDPOINT : "http://aml-api-development.d-roid.de/api",
    LLMS: [
       {
         label: "Azure OpenAI (GPT-3.5-Turbo-16k)",
         name: "azure_openai/gpt-3.5-turbo-16k"
       },
       {
         label: "Nemo (GPT20B)",
         name: "nemo/gpt20b"
       },
       {
         label: "Nemo (GPT-43B-001)",
         name: "nemo/gpt-43b-001"
       },
       {
         label: "Nemo (GPT-43B-002)",
         name: "nemo/gpt-43b-002"
       },
     ],
     THRESHOLD1_SemSim: 0.91,
     THRESHOLD2_SemSim: 0.88 
   };

const dev = {
   API_URL: "http://localhost:8000/",
   LLMS: [
       {
         label: "Azure OpenAI (GPT-3.5-Turbo-16k)",
         name: "azure_openai/gpt-3.5-turbo-16k"
       },
       {
         label: "Nemo (GPT20B)",
         name: "nemo/gpt20b"
       },
       {
         label: "Nemo (GPT-43B-001)",
         name: "nemo/gpt-43b-001"
       },
       {
         label: "Nemo (GPT-43B-002)",
         name: "nemo/gpt-43b-002"
       },
     ],
  THRESHOLD1_SemSim: 0.91,
  THRESHOLD2_SemSim: 0.88
};

const threshold1_SemSim = 0.91
const threshold2_SemSim = 0.88

export const config = process.env.NODE_ENV === "development" ? dev : prod;