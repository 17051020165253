import api from "../config/axiosConfigs";

const validateToken = async () => {
  try {
    const response = await api.get('/verifytoken');

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error validating token:', error);
    return false;
  }
};

export default validateToken;
