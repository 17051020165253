import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import validateToken from './authService';

const TokenValidationWrapper = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenValidity = async () => {
      const isValid = await validateToken();

      if (!isValid) {
        // Redirect to the login page if the token is not valid
        navigate('/login',  {
                  state:{
                    error: "Session expired, please login"
                  }
                });
      }
    };

    // Check token validity every 5 minutes (adjust the interval as needed)
    const intervalId = setInterval(checkTokenValidity, 1 * 60 * 10000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run on component mount and unmount

  return <>{children}</>;
};

export default TokenValidationWrapper;
