import axios from 'axios';
import Cookies from 'js-cookie';
import { config } from './config';

const api = axios.create({
  baseURL: config.API_URL,
});

// Add default headers
api.defaults.headers.common['Content-Type'] = 'application/json';

// Interceptor to add the token to every request
api.interceptors.request.use((config) => {
  const sessionToken = Cookies.get('sessionToken');
  if (sessionToken) {
    config.headers.Authorization = `Bearer ${sessionToken}`;
  }
  return config;
});

api.interceptors.request.use((config) => {
  if (config.headers['Content-Type'] && config.headers['Content-Type'].startsWith('multipart/form-data')) {
    // Don't set content type for file uploads, as it will be automatically set by FormData
    delete config.headers['Content-Type'];
  }
  return config;
});

export default api;
