import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, Select, Typography } from "@mui/material";
import MultiselectDropdown from "../documentSearchDropDown/dropdown";
import MultipleSelectChip from "../documentSearchDropDown/dropdownChat";
import React, { useState } from "react";

function SettingsDialog({ open, handleClose, language, handleChange, llms, llm, handleChangeLLM, selectedTags, setSelectedTags}) {
    // Dialog for accessing settings like microphone control, LLM name, and tags
    const [enableDocumentSelection, setEnableDocumentSelection] = useState(false);
    const [selectedDocumentOption, setSelectedDocumentOption] = useState('value2');

    const options = [
      // { value: 'value1', label: 'None' },
      { value: 'value2', label: 'All' },
      { value: 'value3', label: 'Subset' },
    ];
    
    const handleDocumentOptionChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedDocumentOption(selectedValue);
      setEnableDocumentSelection(selectedValue === 'value3');
      if (selectedValue !== 'value3') {
        setSelectedTags([])
      }
    };
  
    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {/* Not Used for AML yet */}
            {/* Select language for speech input */}
            {/* <FormControl sx={{ m: 1, width: 400 }}>
              <InputLabel>Speech-To-Text</InputLabel>
              <Select
                native
                value={language}
                onChange={handleChange}
                input={<OutlinedInput label="Language" id="demo-dialog-native" />}
              >
                <option value={"en-GB"}>English</option>
                <option value={"de-DE"}>German</option>
              </Select>
            </FormControl> */}
            {/* Select LLM for query processing */}
            <FormControl sx={{ m: 1, width: 400 }}>
              <InputLabel id="demo-simple-select-label">LLM</InputLabel>
              <Select
                  native
                  value={llm}
                  onChange={handleChangeLLM}
                  input={<OutlinedInput label="LLM" id="demo-dialog-native" />}
              >
                  {llms !== undefined && llms.map((llm, index) => (
                      <option value={index}>{llm.label}</option>
                  ))}
              </Select>
          </FormControl>
          {/* Search in Documents */}
          Do you want to search in documents?
          <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="demo-simple-select-label">Documents</InputLabel>
            <Select
                native
                value={selectedDocumentOption}
                onChange={handleDocumentOptionChange}
                input={<OutlinedInput label="Documents" id="demo-dialog-native" />}
            >
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
          {/* Conditionally render MultiselectDropdown */}
          {enableDocumentSelection && (<MultipleSelectChip selectedTags = {selectedTags} setSelectedTags={setSelectedTags} />)}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
}

export default SettingsDialog;