import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import api from "../../config/axiosConfigs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function MultipleSelectChip({selectedTags, setSelectedTags}) {
    const [tags, setTags] = useState([]); 

    //Load the tags automatically when the page loads
    useEffect(() => {
        api.get("/listTags").then(
            (response) => {
                let options = [];
                if (response && response.data.tags && Array.isArray(response.data.tags)) {
                    options = response.data.tags.map(tag => (tag));
                }
                if (options.length > 0) {
                    console.log("OPTIONS: ")
                    console.log(options)
                    setTags(options);
                }
                else {
                    console.log("No tags found");
                }
            }
        ).catch(() => {
            console.log("Failed to load Tags! using the dafult tag only");
        }
        );
    }, []);

    const handleChange = (event) => {
        const selectedValues = event.target.value;
        const selectedTagObjects = tags.filter(tag => selectedValues.includes(tag));
        setSelectedTags(selectedTagObjects);
    };

    return (
        <div>
        <FormControl sx={{ m: 1, width: 400 }}>
            <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
            <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={selectedTags.map(tag => tag)}
            label={''}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedTags.map((tag) => ( // Render chips based on selectedTags state
                    <Chip key={tag} label={tag} />
                ))}
                </Box>
            )}
            MenuProps={MenuProps}
            >
            {tags.map((tag) => (
                <MenuItem
                key={tag}
                value={tag}
                >
                {tag}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
        </div>
    );
}