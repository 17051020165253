import React, { useState } from 'react';
import { Button, IconButton, TextField, Stack, Typography, Dialog, DialogContent, DialogContentText } from "@mui/material";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import api from "../../config/axiosConfigs";
import './feedBackDialog.css';
import { useEffect } from 'react';

// Like button icon component
const FeedBackButtonIconsLike = ({ review: toggle }) => (
    toggle ? <ThumbUpIcon sx={{ color: "primary.main" }} /> : <ThumbUpOffAltIcon sx={{ color: "primary.main" }} />
);

// Dislike button icon component
const FeedBackButtonIconsDislike = ({ review: toggle }) => (
    toggle ? <ThumbDownIcon sx={{ color: "primary.main" }} /> : <ThumbDownOffAltIcon sx={{ color: "primary.main" }} />
);

const FeedBackStack = ({ message, messages }) => {
    const [openDialog, setDialogOpen] = useState(false);
    const [likeIcon, setLikeIcon] = useState(false);
    const [dislikeIcon, setDislikeIcon] = useState(false);
    const [review, setReview] = useState('');
    const [content, setContent] = useState(messages);

    useEffect(() => {
        const currentMessage = content.find(msg => msg.content === message.content);

        if (currentMessage) {
            setLikeIcon(currentMessage.liked === true);
            setDislikeIcon(currentMessage.liked === false);
            setReview(currentMessage.review)
        }
    }, []);

    // Handle click event for sending feedback
    const handleClickSend = async (updatedContent) => {
        const payload = {
            reviewId: sessionStorage.getItem('reviewId'),
            content: updatedContent
        };

        try {
            setDialogOpen(false);
            await api.post('/addReview', payload);
        } catch (error) {
            console.log("Error while sending a review", error);
        }
    };

    // Handle like and dislike button clicks
    const handleLikeDislike = (like, dislike) => {
        setLikeIcon(like);
        setDislikeIcon(dislike);

        // Create a variable for updated content
        const updatedContent = {
            'message' : message['content'],
            'liked' : like,
            'review' : review
        }
        // Update content using the variable
        setContent(updatedContent);
        handleClickSend(updatedContent);
    };

    return (
        <Stack direction="row" spacing={1} style={{ marginTop: "-25px" }}>
            {/* Like Button */}
            <Tooltip title="Like">
                <IconButton onClick={() => {
                    setLikeIcon(!likeIcon)
                    if(likeIcon){
                        handleLikeDislike(null, false)
                    }else{
                        handleLikeDislike(true, false)
                    }
                }}>
                    <FeedBackButtonIconsLike review={likeIcon} />
                </IconButton>
            </Tooltip>

            {/* Dislike Button */}
            <Tooltip title="Dislike">
                <IconButton onClick={() => {
                    if(dislikeIcon){
                        console.log(dislikeIcon)
                        handleLikeDislike(null, false)
                    }else{
                        console.log(dislikeIcon)
                        handleLikeDislike(false, true)
                    }
                }}>
                    <FeedBackButtonIconsDislike review={dislikeIcon} />
                </IconButton>
            </Tooltip>

            {/* Message Button */}
            <Tooltip title="Give us feedback">
                <IconButton onClick={() => setDialogOpen(true)}>
                    <EmailIcon sx={{ color: "primary.main" }} />
                </IconButton>
            </Tooltip>

            {/* Dialog for feedback input */}
            <Dialog open={openDialog} onClose={() => setDialogOpen(false)} maxWidth={'xl'}>
                <DialogContentText>
                    <Typography className="feedback-text-box" color={"black"}>
                        We value your input! Please take a moment to share your feedback with us.
                    </Typography>
                </DialogContentText>
                <DialogContent>
                    <Stack>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Review"
                            multiline
                            rows={4}
                            value={review}
                            variant="standard"
                            onChange={(e) => setReview(e.target.value)}
                        />
                        <div className="feedback-send-button">
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ color: 'white', minWidth: "100px" }}
                                onClick={() => {
                                    handleLikeDislike(likeIcon, dislikeIcon);
                                }}
                            >Send</Button>
                        </div>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Stack>
    );
};

export default FeedBackStack;
