import { createTheme } from "@mui/material/styles";

const darkThemeDrawer = createTheme({
    palette: {
        mode: "dark",
        primary: {
          main: '#fff',
        },
        secondary: {
          main: '#43B02A',
        },
      },
    typography: {
        h1: {
            fontSize: "40px"
        },
        h2: {
            fontSize: "30px"
        },
        fontFamily: `Calibri`,
        fontSize: 18,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    },
})

export default darkThemeDrawer;
