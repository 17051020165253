import React from "react";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";

function DeleteConfirmation({ handleClickCancelDelete, handleClickConfirmDelete, currentItemDelete, confirmationTitle="Confirmation required.", confirmationInformation="", confirmationQuestion="Do you really want to delete this file?", confirmationButtonText="Confirm", paperClassName="delete-confirmation" }) {
    return (
        <div>
            <div className="dialogue-backdrop"/>
            <Paper elevation={3} className={paperClassName}>
                <Box sx={{ m: 2 }}>
                    <Typography gutterBottom variant="h5" padding="10px">
                    <Divider>{confirmationTitle}</Divider>
                    </Typography>
                    <Typography color="text.secondary" variant="body1" align='left'>
                        {confirmationInformation}
                    </Typography>
                </Box>
                <Divider variant="middle" />

                <Box sx={{ m: 2 }}>
                    <Typography gutterBottom variant="body1" align='left'>
                    {confirmationQuestion}
                    </Typography>
                </Box>

                <Box sx={{ mt: 3, ml: 1, mb: 1 }} paddingBottom="10px" align='left'>
                    <Button size="large" style={{ color: "grey" }} onClick={(e) => handleClickCancelDelete(e)}>Cancel</Button>
                    <Button size="large" color="secondary" onClick={(e) => handleClickConfirmDelete(e)} >
                    {confirmationButtonText}
                    </Button>
                </Box>
            </Paper>
        </div>
    )
}

export default DeleteConfirmation;