import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import api from "../config/axiosConfigs";

function EnhancedGoogleSearch() {

    const [query, setQuery] = useState(null);
    const [llmResult, setLlmResult] = useState(null);

    const [llm, setLLM] = useState(0);
    const llms = [
      "openai/text-davinci-003"
    ]

    const handleClick = (event, text) => {
        const data = {"query": query, llm: llms[llm]}
    
        api.post("/internetSearch", {
            body: data,
            mode: 'cors'
        }).then((response) => {
          console.log(response)
          if(response.data.answer === undefined || response.data.answer.length === 0){
            setLlmResult("Sorry, but I couldn't generate a meaningful response for your query. Please try rephrasing your question or providing more context so I can better assist you.")
          } else{
            setLlmResult(response.data.answer)
          }        
        }).catch((exeption) => {
          console.log(exeption)
          setLlmResult("Internal Application Error. Please try again later.")
        });
      };

      const handleChange = (event) => {
        setLLM(event.target.value);
      }; 
   

    return (
        <div style={{ width: "100%", display: "flex" }}>
            <Box sx={{ width: "100%", textAlign: "center", alignItems: 'center' }}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    display="stretch"
                    spacing={2}
                >
                    <Box sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1, gap: 2 }}>
                        <TextField id="outlined-multiline-flexible"
                            label="Please enter query"
                            multiline
                            minRows={4}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            sx={{
                                // width:"550px",
                                '.MuiInputBase-input': { fontSize: '1.5rem' }
                            }} />
                        <Stack sx={{ display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1, gap: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">LLM</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={llm}
                                    label="LLM"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>OpenAI</MenuItem>
                                    {/* <MenuItem value={1}>OpenAI</MenuItem>
                                    <MenuItem value={2}>Lama</MenuItem> */}
                                </Select>
                            </FormControl>
                            <Button
                                sx={{
                                    width: "200px",
                                    fontSize: "1.2rem",
                                    iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
                                }}
                                variant="outlined"
                                startIcon={<SearchIcon />}
                                onClick={(e) => handleClick(e, query)} >
                                Search
                            </Button>
                        </Stack>
                    </Box>
                    <TextField sx={{
                        width: "60%",
                        '.MuiInputBase-input': { fontSize: '1.5rem', lineHeight: 1.5, padding: "10px" }
                    }}
                        multiline
                        label="LLM Result"
                        id="Outlined secondary"
                        minRows={8}
                        value={llmResult}
                        focused
                        onChange={(e) => setLlmResult(e.target.value)}
                    />

                </Stack>
            </Box>
        </div>
    )
}

export default EnhancedGoogleSearch;