import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TagsInput from "../tags/TagsInput";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function UploadWithAttributes({ handleClickCancelUpload, handleClickConfirmUpload, document, setDocument, formTitle = "Document Upload", buttonText="Upload Document"}) {
  const [tags, tagsSetter] = useState(document.tags);
  const subTitle = formTitle === "Document Upload"? "Currently uploading:" : "Currently editing:";
  var citableDateValue;
  if (document.citableDate) {
    citableDateValue = dayjs(new Date(document.citableDate));
  } else {
    // If document.CitableDate is not defined or null, you can choose to set value to null or another default value.
    citableDateValue = null;
  }
  return (
    <div>
      <div className="dialogue-backdrop" />
      <Paper elevation={3} className="upload-with-attributes">
        <Box sx={{ m: 2 }}>
          <Typography gutterBottom variant="h5" padding="10px">
            <Divider>{formTitle}</Divider>
          </Typography>
          <Typography color="text.secondary" variant="body1" component='div'>
            <Box component='span' fontWeight='fontWeightBold'>{subTitle}</Box> {document.name}
          </Typography>
        </Box>
        <Divider variant="middle" />

        <Box
          component="span"
          sx={{
            textAlign: "left",
            alignItems: "left",
            justifyContent: "left",
            display: 'block',
            p: 1,
            m: 1
          }}>
          <Stack
            direction="column"
            justifyContent="left"
            alignItems="left"
            display="flex"
            spacing={0}
          >
            <Typography color="text.secondary" variant="body1" width="100%" paddingBottom={1}>
              Author
            </Typography>
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="left"
              display="stretch"
              spacing={0}
            >
              <TextField
                label="Author Name"
                id="author-first-name"
                value={document.authorFullName == "Unknown"? '': document.authorFullName }
                onChange={(e) => {
                  setDocument((docstate) => ({
                    ...docstate,
                    authorFullName: e.target.value,
                  }));
                }}
                sx={{ m: 1 }}
              />
            </Stack>
            <Typography color="text.secondary" variant="body1" width="100%" paddingBottom={1} paddingTop={1}>
              Document Information
            </Typography>
            <Box>
              <Stack
                direction="row"
                justifyContent="left"
                alignItems="left"
                display="stretch"
                spacing={0}
              >
                <TextField
                  label="Full Title"
                  id="full-title"
                  value={document.fullTitle}
                  onChange={(e) => {
                    setDocument((docstate) => ({
                      ...docstate,
                      fullTitle: e.target.value,
                    }));
                  }}
                  sx={{ m: 1 }}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="left"
                alignItems="left"
                display="stretch"
                spacing={0}
              >
                <TextField
                  label="Short Title"
                  id="short-title"
                  onChange={(e) => {
                    setDocument((docstate) => ({
                      ...docstate,
                      shortTitle: e.target.value,
                    }));
                  }}
                  sx={{ m: 1 }}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="left"
                alignItems="left"
                display="stretch"
                spacing={0}
              >

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} sx={{mb : 1.5, ml: 1, borderColor: "white !important"}}>
                  <DatePicker 
                    label="Citation Date"
                    value={citableDateValue}
                    onChange={(newDate) => {
                        setDocument((docstate) => ({
                          ...docstate,
                          citableDate: new Date(newDate).toUTCString(),
                        }))
                      }
                    }
                    />
                </DemoContainer>
              </LocalizationProvider>
            
              </Stack>
            </Box>
          </Stack>
          <Divider variant="middle" style={{marginTop: 3}}/>
        </Box>
        <div style={{display: "flex", alignItems: "baseline", flexDirection: "column", padding: 10, paddingTop: 0, margin: 10, marginTop: 0}}>
          <Typography color="text.secondary" variant="body1" width="100%" paddingBottom={1} display={"flex"}>
                Click enter to add a Tag.
          </Typography>
          <TagsInput 
            tags={tags}
            onTagsChange={(newTags) => {
              tagsSetter(newTags)
              setDocument((docstate) => ({
                ...docstate,
                tags: newTags,
              }));
            }
          } 
            />
        </div>
        <Box sx={{ mt: 3, ml: 1, mb: 1 }} paddingBottom="10px">
          <Button size="large" style={{ color: "grey" }} onClick={(e) => handleClickCancelUpload(e)}>Cancel</Button>
          <Button size="large" color="primary" onClick={(e) => handleClickConfirmUpload(e, document.file)}>{buttonText}</Button>
        </Box>

      </Paper>
    </div>
  );
}

export default UploadWithAttributes;