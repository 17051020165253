import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

function ChatCreation({ handleClickCancelChatCreation, handleClickConfirmChatCreation, chat, setChat, formTitle = "New Chat", buttonText="Create New Chat", subTitle="Please choose a name for your new chat."}) {
  return (
    <div>
      <div className="dialogue-backdrop" />
      <Paper elevation={3} className="chat-creation">
        <Box sx={{ m: 2 }}>
          <Typography gutterBottom variant="h5" padding="10px">
            <Divider>{formTitle}</Divider>
          </Typography>
          <Typography color="text.secondary" variant="body1" component='div'>
            {subTitle}
          </Typography>
        </Box>
        <Box
          component="span"
          sx={{
            textAlign: "left",
            alignItems: "left",
            justifyContent: "left",
            display: 'block',
            p: 1,
            m: 1
          }}>
          <Stack
            direction="column"
            justifyContent="left"
            alignItems="left"
            display="flex"
            spacing={0}
          >
            <TextField
                label="Chat Name"
                id="chat-name"
                value={ chat.chatName }
                onChange={(e) => {
                  setChat((chatState) => ({
                    ...chatState,
                    chatName: e.target.value,
                  }));
                }}
                sx={{ m: 1 }}
                width="100%"
              />
          </Stack>
          <Divider variant="middle" style={{marginTop: 3}}/>
        </Box>
        <Box sx={{ mt: 3, ml: 1, mb: 1 }} paddingBottom="10px">
          <Button size="large" style={{ color: "grey" }} onClick={() => handleClickCancelChatCreation()}>Cancel</Button>
          <Button size="large" color="primary" onClick={() => handleClickConfirmChatCreation()}>{buttonText}</Button>
        </Box>
      </Paper>
    </div>
  );
}

export default ChatCreation;
